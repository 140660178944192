<script>
import Layout from '@layouts/main.vue'
import VerticleArticleComponent from '@components/vertical-article-component.vue'

export default {
  page: {
    title: 'Blog',
    meta: [{ name: 'description', content: 'The Blog page.' }],
  },
  components: { Layout, VerticleArticleComponent },
  data() {
    return {
      articles: [
        {
          title: 'Assurance vétérinaires',
          date: ' 10 ',
          buttonText: 'En savoir +',
          imgSrc: 'v-article-img-02.png',
        },
        {
          title: 'Expert en assurances',
          date: '22 ',
          buttonText: 'En savoir +',
          imgSrc: 'v-article-img-01.png',
        },
      ],
      agency: 'Notre blog',
    }
  },
  mounted() {},
  methods: {
    selectKeyword() {
      const el = event.srcElement
      const parent = el.parentElement.parentElement
      const active = parent.querySelector('.' + this.$style.active)
      if (active) {
        active.classList.remove(this.$style.active)
      }
      el.classList.add(this.$style.active)
    },
  },
}
</script>

<template>
  <Layout>
    <section>
      <div
        :class="$style.headerBg"
        :style="{
          backgroundImage:
            'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,1) 30%, rgba(255,255,255,0) 100%), url(' +
            require('@assets/images/contact-bg.png') +
            ')',
        }"
      ></div>
      <div class="container">
        <div :class="$style.paddingTop">
          <div class="row">
            <div class="col col-lg-7 col-md-7">
              <h2
                :class="$style.pageTitle"
                class="font-weight-bolder title-section-desc"
                >Notre Blog</h2
              >
              <h3 class="font-weight-bolder title-section"
                >Loren Epsom dolor eat.</h3
              >
            </div>
          </div>
          <div class="row mb-5">
            <div class="col col-md-5 col-sm-10">
              <p
                >Xenius Consulting construit des stratégies marketing digitales
                sur mesure. Nous mettons notre expertise à votre service pour
                vous aider à adapter les outils digitaux adéquats à votre
                métier</p
              >
            </div>
          </div>
          <div class="row mt-5">
            <ul :class="$style.keywordsList">
              <li>
                <a href="#" @click.prevent="selectKeyword"> All </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> Créativity </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> UX design </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword">
                  Design graphique
                </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> Développement </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> Application </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> mobile </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> Identité </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> visuelle </a>
              </li>
              <li>
                <a href="#" @click.prevent="selectKeyword"> Logos </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="padder-50">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 pt-5">
            <div class="pr-md-5">
              <VerticleArticleComponent v-bind="articles[0]" />
            </div>
          </div>
          <div class="col">
            <div class="pl-md-5">
              <VerticleArticleComponent v-bind="articles[1]" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="padder-50">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 pt-5">
            <div class="pr-md-5">
              <VerticleArticleComponent v-bind="articles[0]" />
            </div>
          </div>
          <div class="col">
            <div class="pl-md-5">
              <VerticleArticleComponent v-bind="articles[1]" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="padder-50">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 pt-5">
            <div class="pr-md-5">
              <VerticleArticleComponent v-bind="articles[0]" />
            </div>
          </div>
          <div class="col">
            <div class="pl-md-5">
              <VerticleArticleComponent v-bind="articles[1]" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="padder-50">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 pt-5">
            <div class="pr-md-5">
              <VerticleArticleComponent v-bind="articles[0]" />
            </div>
          </div>
          <div class="col">
            <div class="pl-md-5">
              <VerticleArticleComponent v-bind="articles[1]" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
</template>

<style lang="scss" module>
@import '@design';
.discuterProjet {
  position: absolute;
}
.headerBg {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 75vh;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
}
.pageTitle {
  color: $color-theme-orange;
}
.paddingTop {
  padding-top: 30vh;
}
.keywordsList {
  list-style-type: none;
  li {
    display: inline;
    margin-right: 1.2em;
    a {
      &.active {
        font-weight: 600;
        color: $color-theme-orange;
        border-bottom: 1px solid $color-theme-orange;
      }
    }
  }
}
</style>
