<script>
export default {
  props: {
    title: String,
    date: String,
    buttonText: String,
    imgSrc: String,
  },
};
</script>

<template>
  <div class="article" :class="$style.verticalArticle">
    <div :class="$style.content">
      <div :class="$style.imageContainer">
        <img :src="require(`@assets/images/${imgSrc}`)" alt="Agency Life" />
        <!-- <ObjectFitComponent
                        :url="require(`@assets/images/${imgSrc}`)"
                /> -->
      </div>
      <div class="py-4">
        <h4 :class="$style.title" class="title-article">{{ title }}</h4>
        <p :class="$style.date" class="title-article-date">{{ date }}</p>
      </div>
      <button class="btn btn--smaller">
        <span class="circle bg-color-orange"> </span>
        <span class="button-text">{{ buttonText }}</span>
      </button>
    </div>
  </div>
</template>

<style lang="scss" module>
@import "@design";

.verticalArticle {
  .content {
    .title {
      display: block;
    }
    span.date {
      display: block;
    }
    img {
      max-width: 100%;
    }
  }
}
</style>
